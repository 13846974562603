import React, { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import DatePicker from "react-datepicker";
import { useForm, SubmitHandler } from "react-hook-form";

import Loader from "../Loader";
import SentInvitaions from "../SentCorporateInvitations";

import {
  BulkBookingEventQuery,
  CorporateInvitationQuery,
} from "../../Query/Invitations";

import { popoverAlert } from "../../utils/Alert";

import {
  BulkInvitation,
  CorporateInvitation,
} from "../../Interfaces/Invitations";

import InfoIcon from "../../images/info_white_icon.png";
import GoBackImg from "../../images/back_white@2x.png";

import * as SendCorporateInvitationStyle from "./SendCorporateInvitation.module.scss";
import Server from "../../utils/Server";
import { AxiosError } from "axios";
import { getFormattedDate, getFormattedDateTime } from "../../utils/FormatTime";
import moment from "moment";
import Logger from "../../utils/Logger";

interface Props {
  id: string;
}

interface Inputs {
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  guestName: string;
  guestContactNo: string;
  guestRegNo: string;
  hostName: string;
  vehicleType: "2W" | "4W" | "OTHER";
}

const hours = [
  { value: "00:00", label: "Midnight" },
  { value: "01:00", label: "1 AM" },
  { value: "02:00", label: "2 AM" },
  { value: "03:00", label: "3 AM" },
  { value: "04:00", label: "4 AM" },
  { value: "05:00", label: "5 AM" },
  { value: "06:00", label: "6 AM" },
  { value: "07:00", label: "7 AM" },
  { value: "08:00", label: "8 AM" },
  { value: "09:00", label: "9 AM" },
  { value: "10:00", label: "10 AM" },
  { value: "11:00", label: "11 AM" },
  { value: "12:00", label: "Noon" },
  { value: "13:00", label: "1 PM" },
  { value: "14:00", label: "2 Pm" },
  { value: "15:00", label: "3 Pm" },
  { value: "16:00", label: "4 Pm" },
  { value: "17:00", label: "5 Pm" },
  { value: "18:00", label: "6 Pm" },
  { value: "19:00", label: "7 Pm" },
  { value: "20:00", label: "8 Pm" },
  { value: "21:00", label: "9 Pm" },
  { value: "22:00", label: "10 Pm" },
  { value: "23:00", label: "11 Pm" },
  { value: "24:00", label: "Midnight" },
];

const Index = ({ id }: Props) => {
  const [startDate, setStartDate] = useState<Date>();
  const [isViewSentInvitation, setIsViewSentInvitation] = useState(false);

  const { register, handleSubmit, reset } = useForm();

  const { data, error, loading } = useQuery<{
    privateInvitation: CorporateInvitation;
  }>(CorporateInvitationQuery, {
    variables: {
      id: `/private_invitations/${id}`,
    },
  });

  useEffect(() => {
    if (error) {
      Logger.showError(error);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      setIsViewSentInvitation(
        moment(data?.privateInvitation.bookTo).toDate() < moment().toDate()
      );
    }
  }, [data]);

  const onHandleSubmit: SubmitHandler<Inputs> = (formData) => {
    if (!startDate)
      return popoverAlert({
        msg: "Invitation Date Is Required",
        icon: "warning",
      });

    const data = {
      contactNumber: formData.guestContactNo,
      guestName: formData.guestName,
      hostName: formData.hostName,
      regNo: formData.guestRegNo,
      bookFrom: getFormattedDateTime(
        startDate.toISOString(),
        formData.startTime
      ),
      bookTo: getFormattedDateTime(startDate.toISOString(), formData.endTime),
      vehicle: formData.vehicleType,
    };

    Server.post(`/privateInvitation/${id}/parkingInvitation`, data)
      .then((response) => {
        reset();
        popoverAlert({
          msg: "Invitation Has Been Sent.",
          icon: "success",
          title: "SUCCESS",
        });
      })
      .catch((err: AxiosError) => {
        Logger.showError(err);
      });
  };

  const getEndOfMonth = (): Date => {
    const t = moment(data?.privateInvitation.startAt).endOf("month").toDate();
    return t;
  };

  return (
    <>
      {isViewSentInvitation ? (
        <div className={SendCorporateInvitationStyle.container}>
          <div className={SendCorporateInvitationStyle.list__header}>
            <h5 className="color-primary boldText textLg">
              {data?.privateInvitation?.parkingArea?.name}
            </h5>
            {moment(data?.privateInvitation?.bookTo).toDate() >
              moment().toDate() && (
              <button onClick={() => setIsViewSentInvitation(false)}>
                <img src={GoBackImg} alt="back" width={24} height={24} />
                <span>Go Back</span>
              </button>
            )}
          </div>
          {data?.privateInvitation.id && (
            <div>
              <SentInvitaions inviteId={data?.privateInvitation.id} />
            </div>
          )}
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(onHandleSubmit)}
          className={SendCorporateInvitationStyle.container}
        >
          <h5 className="color-primary boldText textLg">
            Corporate Invitation
          </h5>
          <div className={SendCorporateInvitationStyle.invitationDetails}>
            <div className={SendCorporateInvitationStyle.instrunctions}>
              <p>1. Select Event Date</p>
              <p>2. Upload Phone number of the invitee.</p>
              <p>
                3. Check the event to see numbers of invitees have confirmed
                participation by booking a parking space
              </p>
            </div>
            <div className={SendCorporateInvitationStyle.action}>
              <button type="submit">
                <span>Send Invitation</span>
              </button>
              <button
                onClick={(e) => setIsViewSentInvitation(true)}
                type="button"
              >
                <span>Invitation List</span>
                <img src={InfoIcon} alt="info" width={20} height={20} />
              </button>
            </div>
          </div>
          <div className={SendCorporateInvitationStyle.event}>
            <h5 className="color-primary boldText textLg">Event Details</h5>
            <div className={SendCorporateInvitationStyle.event__content}>
              <div
                className={SendCorporateInvitationStyle.event__content__date}
              >
                <DatePicker
                  selected={startDate}
                  selectsStart
                  dateFormat="dd-MM-yyyy"
                  onChange={(date) => {
                    if (date) setStartDate(date);
                  }}
                  placeholderText="Invitation Date"
                  maxDate={getEndOfMonth()}
                  minDate={moment().toDate()}
                  dayClassName={(date) => {
                    const d: any = date.getDay();
                    const purchasedDays =
                      data?.privateInvitation.privateInvitationBookings.collection.map(
                        ({ bookDay: { code } }) => {
                          if (code === "SUN") return 0;
                          if (code === "MON") return 1;
                          if (code === "TUE") return 2;
                          if (code === "WED") return 3;
                          if (code === "THU") return 4;
                          if (code === "FRI") return 5;
                          if (code === "SAT") return 6;
                        }
                      );

                    if (d) {
                      return !purchasedDays?.includes(d)
                        ? SendCorporateInvitationStyle.disableDay
                        : "";
                    } else return "";
                  }}
                />
              </div>
              <div
                className={SendCorporateInvitationStyle.event__content__time}
              >
                <select required {...register("startTime")}>
                  {hours
                    .filter((_, i) => i !== 24)
                    .map(({ label, value }) => (
                      <option key={`startTime${value}`} value={value}>
                        {label}
                      </option>
                    ))}
                </select>
                <span>To</span>
                <select required {...register("endTime")}>
                  {hours
                    .filter((_, i) => i !== 0)
                    .map(({ label, value }) => (
                      <option key={`endTime${value}`} value={value}>
                        {label}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
          <div className={SendCorporateInvitationStyle.guest}>
            <h5 className="color-primary boldText textLg">Guest Details</h5>
            <div className={SendCorporateInvitationStyle.guest__detail}>
              <input
                required
                {...register("guestContactNo")}
                placeholder="Enter Guest Mobile Number"
              />
              <input
                required
                {...register("guestName")}
                placeholder="Enter Guest Name"
              />
              <input
                required
                {...register("guestRegNo")}
                placeholder="Enter Guest Registration Number"
              />
              <input
                required
                {...register("hostName")}
                placeholder="Enter Host Name"
              />
              <select
                required
                {...register("vehicleType")}
                placeholder="Select Vehicle"
              >
                <option value="2W">2 Wheeler</option>
                <option value="4W">4 Wheeler</option>
                <option value="OTHER">OTHER</option>
              </select>
            </div>
          </div>
        </form>
      )}

      <Loader isLoading={loading} />
    </>
  );
};

export default Index;
