import React from "react";

import Layout from "../../Components/Layout";
import SendCorporateInvitation from "../../Components/SendCorporateInvitation";

const SendCorporateInvitationPage = ({ id }) => {
  return (
    <Layout isPrivate showHeader>
      <SendCorporateInvitation id={id} />
    </Layout>
  );
};

export default SendCorporateInvitationPage;
