import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import moment from "moment";

import Loader from "../Loader";

import {
  GetSentCorporateInvitation as IGetSentCorporateInvitation,
  GetSentCorporateInvitationQuery,
} from "../../Interfaces/Invitations";

import { GetSentCorporateInvitation } from "../../Query/Invitations";

import Logger from "../../utils/Logger";

import * as SentInvitationsStyle from "./SentCorporateInvitations.module.scss";

interface Props {
  inviteId: string;
}

const Index = ({ inviteId }: Props) => {
  const [page, setPage] = useState(1);

  const { data, loading, error, refetch, fetchMore } =
    useQuery<GetSentCorporateInvitationQuery>(GetSentCorporateInvitation, {
      variables: {
        inviteId: inviteId,
        order: [{ createdAt: "DESC" }],
      },
    });

  const [sentInvtation, setSentInvitation] =
    useState<IGetSentCorporateInvitation[]>();

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (error) {
      Logger.showError(error);
    }
  }, [error]);

  useEffect(() => {
    if (data?.parkingInvitations.collection) {
      setSentInvitation(data.parkingInvitations.collection);
    }
  }, [data]);

  return (
    <>
      <div className={SentInvitationsStyle.container}>
        {sentInvtation?.map(
          ({
            bookFrom,
            bookTo,
            contactNumber,
            guestName,
            hostName,
            id,
            regNo,
          }) => (
            <div key={id}>
              <div className={SentInvitationsStyle.card__content}>
                <div className={SentInvitationsStyle.card__content__item}>
                  <h6>Contact Number</h6>
                  <span>{contactNumber}</span>
                </div>
                <div className={SentInvitationsStyle.card__content__item}>
                  <h6>Name</h6>
                  <span>{guestName}</span>
                </div>
                <div className={SentInvitationsStyle.card__content__item}>
                  <h6>Reg No.</h6>
                  <span>{regNo}</span>
                </div>
                <div className={SentInvitationsStyle.card__content__item}>
                  <h6>Parking From</h6>
                  <span>{moment(bookFrom).format("DD/MM/YYYY hh:mm")}</span>
                </div>
                <div className={SentInvitationsStyle.card__content__item}>
                  <h6>Parking Till</h6>
                  <span>{moment(bookTo).format("DD/MM/YYYY hh:mm")}</span>
                </div>
              </div>
            </div>
          )
        )}
        {data?.parkingInvitations &&
          data?.parkingInvitations.paginationInfo.lastPage > page && (
            <div className={SentInvitationsStyle.loadMore}>
              <button
                onClick={() => {
                  fetchMore({
                    variables: {
                      page: page + 1,
                    },
                  })
                    .then((res) => {
                      setPage(page + 1);
                      setSentInvitation(
                        sentInvtation
                          ? [
                              ...sentInvtation,
                              ...res.data.parkingInvitations.collection,
                            ]
                          : res.data.parkingInvitations.collection
                      );
                    })
                    .catch((err) => {
                      Logger.showError(err);
                    });
                }}
              >
                Load More
              </button>
            </div>
          )}
        {data?.parkingInvitations.collection.length === 0 && (
          <span className="boldText color-primary textMd">
            No Invitation Sent
          </span>
        )}
      </div>
      <Loader isLoading={loading} />
    </>
  );
};

export default Index;
