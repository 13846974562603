// extracted by mini-css-extract-plugin
export var container = "SendCorporateInvitation-module--container--K0fOs";
export var invitationDetails = "SendCorporateInvitation-module--invitationDetails--rELQA";
export var instrunctions = "SendCorporateInvitation-module--instrunctions--zc0lY";
export var action = "SendCorporateInvitation-module--action--632ra";
export var event = "SendCorporateInvitation-module--event---zf0M";
export var event__content = "SendCorporateInvitation-module--event__content--MJxIT";
export var event__content__date = "SendCorporateInvitation-module--event__content__date--V+SDt";
export var event__content__time = "SendCorporateInvitation-module--event__content__time--QvYF2";
export var guest = "SendCorporateInvitation-module--guest--wiWwP";
export var guest__detail = "SendCorporateInvitation-module--guest__detail--nOjpB";
export var list__header = "SendCorporateInvitation-module--list__header--IQM5-";
export var disableDay = "SendCorporateInvitation-module--disableDay---yVHe";